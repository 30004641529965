import React from "react";
import Header from "../components/Header";
import HighScoresList from "../components/HighScoresList";
import Footer from "../components/Footer";

export default function HighScores() {
   return (
      <div className="body">
         <Header />
         <HighScoresList />
         <Footer />
      </div>
   );
}
