import React from "react";
import ReactDOM from "react-dom";
import { ScoreProvider } from "./contexts/ScoreContext";
import App from "./App";

ReactDOM.render(
   <ScoreProvider>
      <App />
   </ScoreProvider>,
   document.getElementById("root")
);
