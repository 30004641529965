import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function GameOver({ history }) {
   let score = window.localStorage.score;

   const [scoreMessage, setScoreMessage] = useState("");

   useEffect(() => {
      const saveHighScore = async () => {
         try {
            const post = `{
               "Name": "Kodi",
               "Score": ${score.toString()}
            }`;
            const options = {
               method: "POST",
               body: post,
            };
            const res = await fetch("/.netlify/functions/saveHighScore", options);
            const data = await res.json();

            if (data.id) {
               setScoreMessage("Congrats! You got a high score!!");
            } else {
               setScoreMessage("Sorry, not a high score. Keep trying!");
            }
         } catch (err) {
            console.error(err);
         }
      };
      saveHighScore();
   }, [score]);

   return (
      <div className="body">
         <Header />{" "}
         <div className="hero">
            <div className="card_profile">
               <h1 className="game_over">Game Over</h1>
               <h3 className="highscore_message">{scoreMessage}</h3>
               <p className="game_over2">{window.localStorage.userName} scored:</p>
               <p className="game_over">{score}</p>
               <a href="/game" className="a_special">
                  Play Again?
               </a>
               <a href="/highScores" className="a_special">
                  HIGH SCORES
               </a>
               <Footer />
            </div>
         </div>
      </div>
   );
}
