import React, { Component } from "react";

export default class Card extends Component {
   render() {
      return (
         <a className="a_card" href={this.props.url}>
            <div className="card_list">
               <div className="visual_dark">
                  <div className="inline_votes">
                     <h1 className="card_title">{this.props.header}</h1>
                  </div>
                  <p className="card_font">{this.props.text}</p>
               </div>
            </div>
         </a>
      );
   }
}
