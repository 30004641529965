import React from "react";

function Header() {
   return (
      <div className="header">
         <h1>
            <span className="change_header">T</span>
            <span className="change_header">E</span>
            <span className="change_header">N</span>
            <span className="change_header">S</span>
            <span className="change_header">O</span>
            <span className="change_header">R</span>
            <span className="change_header">" "</span>
            <span className="change_header">R</span>
            <span className="change_header">A</span>
            <span className="change_header">N</span>
            <span className="change_header" style={{ zIndex: "100" }}>
               D
            </span>
            <span className="change_header" style={{ zIndex: "1" }}>
               O
            </span>
         </h1>
         <div className="header_links">
            <a href="/" className="a_special">
               HOME
            </a>
            <a href="/game" className="a_special">
               KODI SAYS GAME
            </a>
            <a href="/highScores" className="a_special">
               HIGH SCORES
            </a>
            {/* <a href="https://www.pibeta.me/iching.html" className="a_special">
               I CHING
            </a> */}
            <a href="/posts" className="a_special">
               POSTS
            </a>
            {/* <a href="https://www.pibeta.me/" className="a_special">
               ABOUT
            </a> */}
         </div>
      </div>
   );
}

export default Header;
