import React from "react";
import DataListColumn from "./DataListColumn";
import MLColumn from "./MLColumn";
import VisualColumn from "./VisualColumn";
import LazyHero from "react-lazy-hero";
import HeroLogo from "../images/markus-spiske-hbb6GkG6p9M-unsplash.jpg";

function Main() {
   return (
      <div className="main">
         <LazyHero color="#05d9e8" imageSrc={HeroLogo} minHeight="30vh">
            <h1 style={{ fontSize: "1.5vh", color: "black" }}>
               A tensor is an algebraic object that describes a (multilinear) relationship between sets of algebraic
               objects related to a vector space
            </h1>
            <h2 style={{ fontSize: "1.5vh" }}>A Tensor Rando is an algebraic object that is a Stan for Data</h2>
            <h5 style={{ fontSize: "1.5vh", color: "black" }}>
               This is a collection of side projects and interesting bookmarks
            </h5>
         </LazyHero>
         <DataListColumn header="Data" text="Capture it" title="Collect it" />
         <MLColumn header="Machine Learning" text="Distill it" title="Knowledge is a form of lossless compression" />
         <VisualColumn header="Visualizations" text="Share it" title="Allow others to also learn from it" />
      </div>
   );
}

export default Main;
