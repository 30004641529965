import React, { useRef } from "react";

export default function BottomRowInput() {
   const nameInput = useRef();
   function handleName() {
      window.localStorage.userName = nameInput.current.value;
   }
   return (
      <div className="card">
         <label style={{ color: "#ff2a6a" }}>Name (4 to 20 characters):</label>

         <input
            type="text"
            id="name"
            name="name"
            required
            minLength="4"
            maxLength="20"
            size="20"
            onChange={handleName}
            ref={nameInput}
         />
      </div>
   );
}
