import React from "react";
import Header from "../components/Header";
import Main from "../components/Main";
import Footer from "../components/Footer";

export default function Home() {
   return (
      <div className="body">
         <Header />
         <Main />
         <Footer />
      </div>
   );
}
