import React from "react";

function Footer() {
   return (
      <>
         <div class="theme-switch-wrapper" style={{ float: "right" }}>
            <div className="footer">
               <label class="theme-switch" for="checkbox">
                  <input type="checkbox" id="checkbox" />
                  <div class="slider round"></div>
               </label>
               <em>DARK MODE</em>
            </div>
         </div>
      </>
   );
}

export default Footer;
