import React, { useState, useEffect } from "react";
import Card from "./Card";

export default function MachineLearningList(props) {
   const [posts, setPosts] = useState([]);

   //use the fetch API to call getHighScores function
   useEffect(() => {
      const loadPosts = async () => {
         try {
            const res = await fetch("/.netlify/functions/getAirtableML");
            const gotPosts = await res.json();
            setPosts(gotPosts);
         } catch (err) {
            console.error(err);
         }
      };
      loadPosts();
   }, []);

   return (
      <div className="hero">
         <div className="card_profile">
            <p style={{ color: "#ff2a6a", fontSize: "2rem" }}>{props.title}</p>
            <ul>
               {posts.map((record) => (
                  <li key={record.id}>
                     <>
                        <Card header={record.fields.Title} text={record.fields.Notes} url={record.fields.URL} />
                     </>
                  </li>
               ))}
            </ul>
         </div>
      </div>
   );
}
