import React, { useRef } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BottomRowInput from "../components/BottomRowInput";
import Kodi from "../images/Kodi.png";

import "./Game.css";

export default function Game({ history }) {
   window.localStorage.score = 0;
   // Establish the variables
   let order = [];
   let playerOrder = [];
   let flash;
   let turn;
   let good;
   let compTurn;
   let intervalId;
   let strict = false;
   let noise = true;
   let on = false;
   let win;

   // Const elements
   const turnCounter = useRef();
   const topLeft = useRef();
   const topRight = useRef();
   const bottomLeft = useRef();
   const bottomRight = useRef();
   const strictButton = useRef();
   const onButton = useRef();
   const startButton = useRef();

   // Functions
   function play() {
      win = false;
      order = [];
      playerOrder = [];
      flash = 0;
      intervalId = 0;
      turn = 1;
      turnCounter.current.innerHTML = 1;
      good = true;
      for (var i = 0; i < 20; i++) {
         order.push(Math.floor(Math.random() * 4) + 1);
      }
      compTurn = true;

      intervalId = setInterval(gameTurn, 800);
   }

   function gameTurn() {
      on = false;

      if (flash === turn) {
         clearInterval(intervalId);
         compTurn = false;
         clearColor();
         on = true;
      }

      if (compTurn) {
         clearColor();
         setTimeout(() => {
            if (order[flash] === 1) one();
            if (order[flash] === 2) two();
            if (order[flash] === 3) three();
            if (order[flash] === 4) four();
            flash++;
         }, 200);
      }
   }

   function one() {
      if (noise) {
         let audio = document.getElementById("clip1");
         audio.play();
      }
      noise = true;
      topLeft.current.style.backgroundColor = "lightgreen";
   }

   function two() {
      if (noise) {
         let audio = document.getElementById("clip2");
         audio.play();
      }
      noise = true;
      topRight.current.style.backgroundColor = "tomato";
   }

   function three() {
      if (noise) {
         let audio = document.getElementById("clip3");
         audio.play();
      }
      noise = true;
      bottomLeft.current.style.backgroundColor = "yellow";
   }

   function four() {
      if (noise) {
         let audio = document.getElementById("clip4");
         audio.play();
      }
      noise = true;
      bottomRight.current.style.backgroundColor = "lightskyblue";
   }

   function clearColor() {
      topLeft.current.style.backgroundColor = "darkgreen";
      topRight.current.style.backgroundColor = "darkred";
      bottomLeft.current.style.backgroundColor = "goldenrod";
      bottomRight.current.style.backgroundColor = "darkblue";
   }

   function flashColor() {
      topLeft.current.style.backgroundColor = "lightgreen";
      topRight.current.style.backgroundColor = "tomato";
      bottomLeft.current.style.backgroundColor = "yellow";
      bottomRight.current.style.backgroundColor = "lightskyblue";
   }

   function check() {
      if (playerOrder[playerOrder.length - 1] !== order[playerOrder.length - 1]) good = false;

      if (playerOrder.length === 36 && good) {
         winGame();
      }

      if (good === false) {
         flashColor();
         turnCounter.current.innerHTML = "NO!";

         setTimeout(() => {
            turnCounter.current.innerHTML = turn;
            clearColor();

            if (strict) {
               window.location.href = "/gameOver";
            } else {
               compTurn = true;
               flash = 0;
               playerOrder = [];
               good = true;
               intervalId = setInterval(gameTurn, 800);
            }
         }, 800);

         noise = false;
      }

      if (turn === playerOrder.length && good && !win) {
         turn++;
         window.localStorage.score++;
         playerOrder = [];
         compTurn = true;
         flash = 0;

         turnCounter.current.innerHTML = turn;
         intervalId = setInterval(gameTurn, 800);
      }
   }

   function winGame() {
      flashColor();
      turnCounter.current.innerHTML = "WIN!";
      on = false;
      win = true;
   }

   // Handle Event Listeners

   function handleStrictChange() {
      if (strictButton != null) {
         if (strictButton.current.checked === true) {
            strict = true;
         } else {
            strict = false;
         }
      }
   }

   function handlePowerChange(event) {
      if (onButton != null) {
         if (onButton.current.checked === true) {
            on = true;
            turnCounter.current.innerHTML = "-";
         } else {
            on = false;
            turnCounter.current.innerHTML = "";
            clearColor();
            clearInterval(intervalId);
         }
      }
   }

   function handleStartChange() {
      if (startButton != null) {
         if (on || win) {
            play();
         }
      }
   }

   function handleTopLeftChange() {
      if (topLeft != null) {
         if (on) {
            playerOrder.push(1);
            check();
            one();
            if (!win) {
               setTimeout(() => {
                  clearColor();
               }, 300);
            }
         }
      }
   }
   function handleTopRightChange() {
      if (topRight != null) {
         if (on) {
            playerOrder.push(2);
            check();
            two();
            if (!win) {
               setTimeout(() => {
                  clearColor();
               }, 300);
            }
         }
      }
   }
   function handleBottomLeftChange() {
      if (bottomLeft != null) {
         if (on) {
            playerOrder.push(3);
            check();
            three();
            if (!win) {
               setTimeout(() => {
                  clearColor();
               }, 300);
            }
         }
      }
   }
   function handleBottomRightChange() {
      if (bottomRight != null) {
         if (on) {
            playerOrder.push(4);
            check();
            four();
            if (!win) {
               setTimeout(() => {
                  clearColor();
               }, 300);
            }
         }
      }
   }

   function setSoundsProfile(profile) {
      window.localStorage.soundProfile = profile;
      window.location.reload(false);
   }

   // uber hack I'm very sorry but this is due in like 30 minutes
   if (!localStorage.soundProfile === true) {
      window.localStorage.lastSoundProfile = window.localStorage.soundProfile;
      window.localStorage.soundProfile = "classic";
   } else if (window.localStorage.lastSoundProfile !== window.localStorage.soundProfile) {
      window.localStorage.lastSoundProfile = window.localStorage.soundProfile;
      window.location.reload(false);
   }

   return (
      <div className="body">
         <Header />

         <div className="hero">
            <div className="card_profile">
               <h1 className="main_title">Kodi Says</h1>
               <form
                  id="selectSounds"
                  style={{ color: "#ff2a6a", fontSize: ".5rem", marginLeft: "27rem", marginTop: "0rem" }}
               >
                  <p>Choose your sonic experience:</p>
                  <input
                     type="radio"
                     name="choice"
                     value="classic"
                     onClick={(event) => setSoundsProfile("classic")}
                     default
                  />{" "}
                  classic
                  <input type="radio" name="choice" value="retro" onClick={(event) => setSoundsProfile("retro")} />{" "}
                  retro arcade
                  <input type="radio" name="choice" value="drums" onClick={(event) => setSoundsProfile("drums")} />{" "}
                  drums
                  <input
                     type="radio"
                     name="choice"
                     value="spooky"
                     onClick={(event) => setSoundsProfile("spooky")}
                  />{" "}
                  spooky
               </form>
               <div id="outer-circle">
                  <div id="topleft" onClick={handleTopLeftChange} ref={topLeft}></div>
                  <div id="topright" onClick={handleTopRightChange} ref={topRight}></div>
                  <div id="bottomleft" onClick={handleBottomLeftChange} ref={bottomLeft}></div>
                  <div id="bottomright" onClick={handleBottomRightChange} ref={bottomRight}></div>
                  <div id="inner-circle">
                     <img className="Kodi" src={Kodi} alt="Kodi Says!" />
                     <div id="switches">
                        <input type="checkbox" className="toggle" id="on" onChange={handlePowerChange} ref={onButton} />
                        <button className="button" id="start" onClick={handleStartChange} ref={startButton}>
                           Start
                        </button>
                        <input
                           type="checkbox"
                           className="toggle"
                           id="strict"
                           onChange={handleStrictChange}
                           ref={strictButton}
                        />
                     </div>
                     <div className="text1">
                        <span>POWER</span>
                        <span>STRICT</span>
                     </div>
                     <div>
                        <audio id="clip1" key={1}>
                           <source src={require(`./sounds/${window.localStorage.soundProfile}/sound1`)}></source>
                        </audio>
                        <audio id="clip2" key={2}>
                           <source src={require(`./sounds/${window.localStorage.soundProfile}/sound2`)}></source>
                        </audio>
                        <audio id="clip3" key={3}>
                           <source src={require(`./sounds/${window.localStorage.soundProfile}/sound3`)}></source>
                        </audio>
                        <audio id="clip4" key={4}>
                           <source src={require(`./sounds/${window.localStorage.soundProfile}/sound4`)}></source>
                        </audio>
                     </div>
                     <div id="turn" ref={turnCounter}></div>
                     <div className="userName">Good luck Player: {window.localStorage.userName}</div>
                  </div>
               </div>
               <BottomRowInput />
            </div>
         </div>
         <span className="header" style={{ fontSize: "1rem" }}>
            CLICK POWER - THEN START - STRICT MODE IS UNFORGIVING - FINAL ROUND IS 36 - WU TANG FOREVER
         </span>
         <Footer />
      </div>
   );
}
