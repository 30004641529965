import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Game from "./pages/Game";
import GameOver from "./pages/GameOver";
import HighScores from "./pages/HighScores";
import Posts from "./pages/Posts";
import Home from "./pages/Home";
import "./components/App.css";

function App() {
   return (
      <>
         <Router>
            <Switch>
               <Route path="/game" component={Game} />
               <Route path="/gameOver" component={GameOver} />
               <Route path="/highScores" component={HighScores} />
               <Route path="/posts" component={Posts} />
               <Route path="/" component={Home} />
            </Switch>
         </Router>
      </>
   );
}

export default App;
