import React, { Component } from "react";
import DataList from "./DataList";

export default class DataListColumn extends Component {
   render() {
      return (
         <div className="card">
            <div className="visual_dark">
               <h1 style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{this.props.header}</h1>
               <div>
                  <p style={{ display: "flex", justifyContent: "center", alignItems: "center", fontFamily: "Roboto" }}>
                     {this.props.text}
                  </p>
               </div>
               <DataList title={this.props.title} />
            </div>
         </div>
      );
   }
}
