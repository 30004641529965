import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PostList from "../components/PostList";

export default function Posts() {
   return (
      <div className="body">
         <Header />
         <PostList />
         <Footer />
      </div>
   );
}
