import React, { useState, useEffect } from "react";
import Card from "./Card";

export default function HighScoresList() {
   const [scores, setScores] = useState([]);

   //use the fetch API to call getHighScores function
   useEffect(() => {
      const loadScores = async () => {
         try {
            const res = await fetch("/.netlify/functions/getHighScores");
            const gotScores = await res.json();
            setScores(gotScores);
         } catch (err) {
            console.error(err);
         }
      };
      loadScores();
   }, []);

   return (
      <div className="hero">
         <div className="card_profile">
            <h1 className="main_title">High Scores</h1>
            <p>This is actually working now, but all new High Scores are name Kodi</p>
            <ul>
               {scores.map((record) => (
                  <li key={record.id}>
                     <>
                        <Card header={record.fields.Name} text={record.fields.Score} />
                     </>
                  </li>
               ))}
            </ul>
         </div>
      </div>
   );
}
